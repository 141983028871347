<template>
  <div>

    <div v-if="progressStore.progress !== true">

      <!--Title Bar-->
      <title-bar :title-value="clientsStore.client.d_nameLast + ', ' +
      clientsStore.client.d_nameFirst" />

      <!--Function Bar-->
      <client-function-bar />

      <v-container fluid class="pa-0">
        <v-row dense>

          <!-- Household-->
          <v-col cols="12" sm="12" md="9">
            <v-card flat>
              <v-toolbar dense flat color="secondary" dark>
                <v-icon>fal fa-users-class</v-icon>
                <v-toolbar-title class="pl-2">Household</v-toolbar-title>

                <v-spacer />
                <client-entry-household />
              </v-toolbar>
            </v-card>

            <v-card flat class="blue-grey lighten-5">
              <v-card-text>

              <v-row dense>
                <v-col cols="12" sm="6" md="3">
                  <text-display :displayLabel="'Household ID'" :displayNumber="householdsStore.household ? householdsStore.household.id : ''" />
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" sm="6" md="6">
                  <text-display v-if="householdsStore.household && householdsStore.household.d_familyStructure === 'Other'" :displayLabel="'Family Structure'" :displayString="'Other - ' + householdsStore.household.d_familyStructureOther" />
                  <text-display v-else :displayLabel="'Family Structure'" :displayString="householdsStore.household ? householdsStore.household.d_familyStructure : ''" />
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <text-display :displayLabel="'Size Of Family'" :displayNumber="householdsStore.household ? householdsStore.household.d_familySize : ''" />
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" sm="12" md="6">
                  <span class="text-h6 secondary--text font-weight-bold">Residence</span>
                  <v-row dense>
                    <v-col cols="12" sm="12">
                      <text-display :displayLabel="'Street Address'" :displayString="householdsStore.household.d_residenceStreet1" />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" sm="12">
                      <text-display :displayLabel="'Apt/Unit #'" :displayString="householdsStore.household.d_residenceStreet2" />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" sm="12">
                      <text-display :displayLabel="'City, State and Zip'" :displayString="householdsStore.household.d_residenceCity + ', ' + householdsStore.household.d_residenceState + ' ' + householdsStore.household.d_residencePostalCode" />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" sm="12">
                      <text-display :displayLabel="'County'" :displayString="householdsStore.household.county_residence ? householdsStore.household.county_residence.d_name : ''" />
                    </v-col>
                  </v-row>

                </v-col>

                <v-col cols="12" sm="12" md="6">
                  <span class="text-h6 secondary--text font-weight-bold">Mailing Address</span>
                  <v-row dense>
                    <v-col cols="12" sm="12">
                      <text-display :displayLabel="'Street Address'" :displayString="householdsStore.household.d_mailingStreet1" />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" sm="12">
                      <text-display :displayLabel="'Apt/Unit #'" :displayString="householdsStore.household.d_mailingStreet2" />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" sm="12">
                      <text-display :displayLabel="'City, State and Zip'" :displayString="householdsStore.household.d_mailingCity + ', ' + householdsStore.household.d_mailingState + ' ' + householdsStore.household.d_mailingPostalCode" />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" sm="12">
                      <text-display :displayLabel="'County'" :displayString="householdsStore.household.county_mailing ? householdsStore.household.county_mailing.d_name : ''" />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" sm="12" md="6">
                  <span class="text-h6 secondary--text font-weight-bold">Primary Applicant</span>
                  <v-row dense>
                    <v-col cols="12" sm="6" md="5">
                      <text-display :displayLabel="'Primary Applicant ID#'" :displayNumber="householdsStore.household.primary_applicant ? householdsStore.household.primary_applicant.id : ''" />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" sm="4">
                      <text-display :displayLabel="'First Name'" :displayString="householdsStore.household.primary_applicant ? householdsStore.household.primary_applicant.d_nameFirst : ''" />
                    </v-col>

                    <v-col cols="12" sm="5">
                      <text-display :displayLabel="'Last Name'" :displayString="householdsStore.household.primary_applicant ? householdsStore.household.primary_applicant.d_nameLast : ''" />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" sm="6" md="4">
                      <text-display :displayLabel="'Date Of Birth'" :displayString="householdsStore.household.primary_applicant ? $_formatDate(householdsStore.household.primary_applicant.d_dateBirth) : ''" />
                    </v-col>

                    <v-col cols="12" sm="6" md="8">
                      <text-display v-if="householdsStore.household.primary_applicant && householdsStore.household.primary_applicant.d_raceOther"
                        :displayLabel="'Race/Ethnicity'"
                        :displayString="householdsStore.household.primary_applicant.d_raceOther" />
                      <text-display v-else-if="householdsStore.household.primary_applicant && Array.isArray(householdsStore.household.primary_applicant.j_race)"
                        :displayLabel="'Race/Ethnicity'"
                        :displayArray="householdsStore.household.primary_applicant.j_race" />
                      <text-display v-else :displayLabel="'Race/Ethnicity'" :displayString="''" />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" sm="10">
                      <text-display :displayLabel="'Relationship To Child'" :displayString="householdsStore.household.primary_applicant ? householdsStore.household.primary_applicant.d_relationship : ''" />
                  </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" sm="12" md="6">
                  <span class="text-h6 secondary--text font-weight-bold">Other Parent/Guardian</span>
                  <v-row dense>
                    <v-col cols="12" sm="6" md="6">
                      <text-display :displayLabel="'Other Parent/Guardian ID#'" :displayNumber="householdsStore.household.other_parent ? householdsStore.household.other_parent.id : ''" />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" sm="4">
                      <text-display :displayLabel="'First Name'" :displayString="householdsStore.household.other_parent ? householdsStore.household.other_parent.d_nameFirst : ''" />
                    </v-col>

                    <v-col cols="12" sm="5">
                      <text-display :displayLabel="'Last Name'" :displayString="householdsStore.household.other_parent ? householdsStore.household.other_parent.d_nameLast : ''" />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" sm="6" md="4">
                      <text-display :displayLabel="'Date Of Birth'" :displayString="householdsStore.household.other_parent ? $_formatDate(householdsStore.household.other_parent.d_dateBirth) : ''" />
                    </v-col>

                    <v-col cols="12" sm="6" md="8">
                      <text-display v-if="householdsStore.household.other_parent && householdsStore.household.other_parent.d_raceOther"
                        :displayLabel="'Race/Ethnicity'"
                        :displayString="householdsStore.household.other_parent.d_raceOther" />
                      <text-display v-else-if="householdsStore.household.other_parent && Array.isArray(householdsStore.household.other_parent.j_race)"
                        :displayLabel="'Race/Ethnicity'"
                        :displayArray="householdsStore.household.other_parent.j_race" />
                      <text-display v-else :displayLabel="'Race/Ethnicity'" :displayString="''" />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" sm="10">
                      <text-display :displayLabel="'Relationship To Child'" :displayString="householdsStore.household.other_parent ? householdsStore.household.other_parent.d_relationship : ''" />
                  </v-col>
                  </v-row>
                </v-col>
              </v-row>

              </v-card-text>
            </v-card>
          </v-col>

          <!--Notes-->
          <v-col cols="12" sm="12" md="3">
            <notes :key="clientsStore.client.id" :noteSection="4" />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="12">
            <contact-methods :key="clientsStore.client.id" />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="12">
            <dependants :key="clientsStore.client.id" />
          </v-col>
        </v-row>
      </v-container>

    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mixin } from '@/mixins/mixin.js';
import TitleBar from '@/components/TitleBar';
import ClientFunctionBar from '@/components/ClientFunctionBar';
import Notes from '@/components/Notes';
import ContactMethods from '@/components/ContactMethods';
import Dependants from '@/components/Dependants';
import TextDisplay from '@/components/TextDisplay';
import ClientEntryHousehold from '@/components/ClientEntryHousehold';

export default {
  name: 'ClientHousehold',
  mixins: [mixin],
  computed: {
    ...mapState({
      clientsStore: (state) => state.clientsStore,
      householdsStore: (state) => state.householdsStore,
      progressStore: (state) => state.progressStore,
    }),
  },
  components: {
	  TitleBar,
    ClientFunctionBar,
    Notes,
    ContactMethods,
    Dependants,
    TextDisplay,
    ClientEntryHousehold,
  },
  created() {
    this.initialize();
  },
  data() {
    return {

    };
  },
  methods: {
	  async initialize() {
      this.$store.dispatch('progressStore/set', true);
      await this.$store.dispatch('clientsStore/read', this.$route.params.clientUUID);
      const client = this.$store.getters['clientsStore/getClient'];
      await this.$store.dispatch('householdsStore/read', client.fk_householdID);
      this.$store.dispatch('progressStore/set', false);
    },

  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
