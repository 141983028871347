<template>
  <div>

    <!-- Title Bar For Section List-->
    <v-card flat>
      <v-toolbar dense flat color="secondary" class="mb-3" dark>
        <v-icon>fal fa-mobile</v-icon>
        <v-toolbar-title class="pl-3">Contact Methods</v-toolbar-title>
      </v-toolbar>
    </v-card>

    <!--Pagination  -->
    <pagination
      :show="contactMethodsStore.contactMethods.data && contactMethodsStore.contactMethods.data.length > 0"
      :showTotal="true"
      :currentPage="contactMethodsStore.contactMethods.current_page"
      :lastPage="contactMethodsStore.contactMethods.last_page"
      :total="contactMethodsStore.contactMethods.total"
      v-on:paginate-event="filter($event)" />

    <!--List-->
    <list-basic
      :show="contactMethodsStore.contactMethods.data && contactMethodsStore.contactMethods.data.length > 0"
      :list-data="contactMethodsStore.contactMethods.data"
      :title="'Contact Methods'"
      :section="5.1"
      @open-entry="openEntry($event)"
    />

  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mixin } from '@/mixins/mixin.js';
import Pagination from '@/components/Pagination';
import ListBasic from '@/components/ListBasic';

export default {
  name: 'ContactMethods',
  components: {
    Pagination,
    ListBasic,
  },
  mixins: [mixin],
  computed: {
    ...mapState({
      contactMethodsStore: (state) => state.contactMethodsStore,
    }),
  },
  created() {
    this.initialize();
  },
  data() {
    return {
	    menu: false,
    };
  },
  methods: {
	  async initialize() {
      /* Clear previous list */
      this.$store.dispatch('contactMethodsStore/clearStore');
      /* Grab client detail */
      const client = this.$store.getters['clientsStore/getClient'];
      const data = {
        fk_householdID: client.fk_householdID,
      };
      /* Pull contact methods list */
      await this.$store.dispatch('contactMethodsStore/fetch', data);
    },

    async loadValueLists() {
      const data2 = {
        id: [4, 6],
      };
      await this.$store.dispatch('valueListsStore/items', data2);
    },

    async openEntry(id) {
      await this.loadValueLists();
      await this.$store.dispatch('contactMethodsStore/entry', id);
    },
  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
