<template>
  <div>
    <v-btn rounded outlined @click="openEntry()"
      ><v-icon small left>fal fa-pencil-alt</v-icon>Edit</v-btn
    >

    <!--Record Entry-->
    <v-dialog v-model="show" scrollable persistent max-width="1000">
      <v-card flat class="white" height="900">
        <v-card-title class="text-h5 secondary--text"
          >Household Details<v-spacer /><v-btn text rounded outlined @click="cancelEntry()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-form ref="entryForm" v-model="valid" @submit.prevent="saveEntry()" lazy-validation>
            <v-container fluid grid-list-md>
              <v-tabs grow show-arrows="mobile" slider-color="secondary" v-model="tab">
                <v-tab>General Info</v-tab>
                <v-tab>Primary Applicant</v-tab>
                <v-tab>Other Parent/Guardian</v-tab>

                <!--General Info-->
                <v-tab-item>
                  <v-card flat>
                    <v-card-text class="px-0">
                      <v-container fluid>
                        <v-row dense>
                          <v-col cols="12" sm="12">
                            <v-radio-group v-model="d_familyStructure" row class="my-0">
                              <v-radio
                                :key="index"
                                v-for="(structure, index) in valueListsStore.valueListItems[8]"
                                :label="structure.d_name"
                                :value="structure.d_name"
                                color="primary"
                                class="pr-2"
                              ></v-radio>
                            </v-radio-group>
                          </v-col>
                        </v-row>

                        <v-row dense v-if="d_familyStructure === 'Other'">
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              v-model="d_familyStructureOther"
                              label="Other Family Structure"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <v-col cols="12" sm="12" md="2">
                            <v-text-field
                              v-model="d_familySize"
                              label="Family Size"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>

                        <span class="text-subtitle-1 secondary--text font-weight-bold"
                          >Residence</span
                        >
                        <!-- Residence Address -->
                        <v-row dense>
                          <!--Street Address-->
                          <v-col cols="12" md="7">
                            <v-text-field
                              v-model="d_residenceStreet1"
                              label="Street Address"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>

                          <!--Street Address 2-->
                          <v-col cols="12" md="5">
                            <v-text-field
                              v-model="d_residenceStreet2"
                              label="App/Unit #"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <!--City-->
                          <v-col cols="12" md="6">
                            <v-text-field
                              v-model="d_residenceCity"
                              label="City"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>

                          <!--State-->
                          <v-col cols="12" md="1">
                            <v-text-field
                              v-model="d_residenceState"
                              label="State"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>

                          <!--Zip-->
                          <v-col cols="12" md="2">
                            <v-text-field
                              v-model="d_residencePostalCode"
                              label="Zip"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>

                          <!--County-->
                          <v-col cols="12" md="3">
                            <v-autocomplete
                              :items="countiesStore.countiesValueList"
                              item-text="d_name"
                              item-value="id"
                              label="County"
                              placeholder=" "
                              persistent-placeholder
                              v-model="fk_countyID_Residence"
                              key="id"
                              clearable
                              clear-icon="fal fa-times-circle"
                            />
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <v-col cols="12" md="12">
                            <v-divider></v-divider>
                          </v-col>
                        </v-row>

                        <!--Mailing Address Same As Residence-->
                        <v-row dense>
                          <v-col cols="12">
                            <v-checkbox
                              v-model="f_sameAddress"
                              label="Mailing Address Same As Residence"
                              color="primary"
                              class="pr-2"
                              @click.native="setMailingAddress(f_sameAddress)"
                              hide-details
                            >
                            </v-checkbox>
                          </v-col>
                        </v-row>

                        <span class="text-subtitle-1 secondary--text font-weight-bold"
                          >Mailing Address</span
                        >
                        <!-- Mailing Address -->
                        <v-row dense>
                          <!--Street Address-->
                          <v-col cols="12" md="7">
                            <v-text-field
                              v-model="d_mailingStreet1"
                              label="Street Address / P.O. Box"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>

                          <!--Street Address 2-->
                          <v-col cols="12" md="5">
                            <v-text-field
                              v-model="d_mailingStreet2"
                              label="App/Unit #"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <!--City-->
                          <v-col cols="12" md="6">
                            <v-text-field
                              v-model="d_mailingCity"
                              label="City"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>

                          <!--State-->
                          <v-col cols="12" md="1">
                            <v-text-field
                              v-model="d_mailingState"
                              label="State"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>

                          <!--Zip-->
                          <v-col cols="12" md="2">
                            <v-text-field
                              v-model="d_mailingPostalCode"
                              label="Zip"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>

                          <!--County-->
                          <v-col cols="12" md="3">
                            <v-autocomplete
                              :items="countiesStore.countiesValueList"
                              item-text="d_name"
                              item-value="id"
                              label="County"
                              placeholder=" "
                              persistent-placeholder
                              v-model="fk_countyID_Mailing"
                              key="id"
                              clear-icon="fal fa-times-circle"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <!--Primary Applicant-->
                <v-tab-item>
                  <v-card flat>
                    <v-card-text class="px-0">
                      <v-container fluid>
                        <v-row dense>
                          <!--Birth Date-->
                          <v-col cols="12" sm="12" md="3">
                            <v-menu
                              :close-on-content-click="false"
                              v-model="menu"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                              <template #activator="{ on }">
                                <v-text-field
                                  v-on="on"
                                  v-model="d_dateBirth1"
                                  label="Date of Birth"
                                  placeholder=" "
                                  persistent-placeholder
                                  prepend-icon="fal fa-calendar-alt"
                                  required
                                  @blur="date1 = $_parseDate(d_dateBirth1)"
                                />
                              </template>
                              <v-date-picker
                                v-model="date1"
                                @input="menu = false"
                                color="primary"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <!--First Name-->
                          <v-col cols="12" md="4">
                            <v-text-field
                              v-model="d_nameFirst1"
                              label="First Name"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>

                          <!--MI-->
                          <v-col cols="12" md="3">
                            <v-text-field
                              v-model="d_nameMiddle1"
                              label="MI"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>

                          <!--Last Name-->
                          <v-col cols="12" md="5">
                            <v-text-field
                              v-model="d_nameLast1"
                              label="Last Name"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <!--Relationship To Child-->
                          <v-col cols="12" sm="12" md="4">
                            <v-autocomplete
                              :items="valueListsStore.valueListItems[2]"
                              label="Relationship To Child"
                              placeholder=" "
                              persistent-placeholder
                              item-text="d_name"
                              item-value="d_name"
                              v-model="d_relationship1"
                              clearable
                              clear-icon="fal fa-times-circle"
                            />
                          </v-col>

                          <!--Wait List ID-->
                          <v-col cols="12" md="2">
                            <v-text-field
                              v-model="d_wlsID1"
                              label="WLS ID"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col cols="12" sm="10">
                            <!--Race-->
                            <v-col cols="12" sm="12" class="px-0">
                              <span class="grey--text text--darken-1 text-caption">Race</span>
                              <v-radio-group dense row class="my-0 py-0">
                                <v-checkbox
                                  dense
                                  :key="index"
                                  v-for="(item, index) in valueListsStore.valueListItems[12]"
                                  v-model="j_race1"
                                  :label="item.d_name"
                                  :value="item.d_name"
                                  color="primary"
                                  multiple
                                  class="pr-2 py-0"
                                ></v-checkbox>
                              </v-radio-group>
                            </v-col>
                            <!--Other Race-->
                            <v-col cols="12" sm="12" md="10" class="px-0 py-0">
                              <v-text-field
                                v-model="d_raceOther1"
                                label="Other Race"
                                placeholder=" "
                                persistent-placeholder
                              >
                              </v-text-field>
                            </v-col>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <!--Other Parent/Guardian-->
                <v-tab-item>
                  <v-card flat>
                    <v-card-text class="px-0">
                      <v-container fluid>
                        <v-row dense>
                          <!--Birth Date-->
                          <v-col cols="12" sm="12" md="3">
                            <v-menu
                              :close-on-content-click="false"
                              v-model="menu2"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                              <template #activator="{ on }">
                                <v-text-field
                                  v-on="on"
                                  v-model="d_dateBirth2"
                                  label="Date of Birth"
                                  placeholder=" "
                                  persistent-placeholder
                                  prepend-icon="fal fa-calendar-alt"
                                  required
                                  @blur="date2 = $_parseDate(d_dateBirth2)"
                                />
                              </template>
                              <v-date-picker
                                v-model="date2"
                                @input="menu2 = false"
                                color="primary"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <!--First Name-->
                          <v-col cols="12" md="4">
                            <v-text-field
                              v-model="d_nameFirst2"
                              label="First Name"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>

                          <!--MI-->
                          <v-col cols="12" md="3">
                            <v-text-field
                              v-model="d_nameMiddle2"
                              label="MI"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>

                          <!--Last Name-->
                          <v-col cols="12" md="5">
                            <v-text-field
                              v-model="d_nameLast2"
                              label="Last Name"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <!--Relationship To Child-->
                          <v-col cols="12" sm="12" md="4">
                            <v-autocomplete
                              :items="valueListsStore.valueListItems[2]"
                              label="Relationship To Child"
                              placeholder=" "
                              persistent-placeholder
                              item-text="d_name"
                              item-value="d_name"
                              v-model="d_relationship2"
                              clearable
                              clear-icon="fal fa-times-circle"
                            />
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col cols="12" sm="10">
                            <!--Race-->
                            <v-col cols="12" sm="12" class="px-0">
                              <span class="grey--text text--darken-1 text-caption">Race</span>
                              <v-radio-group dense row class="my-0 py-0">
                                <v-checkbox
                                  dense
                                  :key="index"
                                  v-for="(item, index) in valueListsStore.valueListItems[12]"
                                  v-model="j_race2"
                                  :label="item.d_name"
                                  :value="item.d_name"
                                  color="primary"
                                  multiple
                                  class="pr-2 py-0"
                                ></v-checkbox>
                              </v-radio-group>
                            </v-col>
                            <!--Other Race-->
                            <v-col cols="12" sm="12" md="10" class="px-0 py-0">
                              <v-text-field
                                v-model="d_raceOther2"
                                label="Other Race"
                                placeholder=" "
                                persistent-placeholder
                              >
                              </v-text-field>
                            </v-col>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn block rounded color="primary" @click="saveEntry()"
            ><v-icon small left>fal fa-check</v-icon>Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";

import { mixin } from "@/mixins/mixin.js";

const { mapFields } = createHelpers({
  getterType: "householdsStore/getEntryField",
  mutationType: "householdsStore/updateEntryField"
});

export default {
  name: "ClientEntryHousehold",
  mixins: [mixin],
  computed: {
    ...mapState({
      householdsStore: state => state.householdsStore,
      valueListsStore: state => state.valueListsStore,
      countiesStore: state => state.countiesStore
    }),
    ...mapFields([
      "show",
      "valid",
      "mode",
      "id",

      "fk_countyID_Residence",
      "fk_countyID_Mailing",
      "d_residenceStreet1",
      "d_residenceStreet2",
      "d_residenceCity",
      "d_residenceState",
      "d_residencePostalCode",
      "d_mailingStreet1",
      "d_mailingStreet2",
      "d_mailingCity",
      "d_mailingState",
      "d_mailingPostalCode",
      "d_familyStructure",
      "d_familyStructureOther",
      "d_familySize",
      "id1",
      "fk_householdID1",
      "d_wlsID1",
      "d_nameFirst1",
      "d_nameMiddle1",
      "d_nameLast1",
      "d_dateBirth1",
      "d_relationship1",
      "j_race1",
      "d_raceOther1",
      "id2",
      "fk_householdID2",
      "d_nameFirst2",
      "d_nameMiddle2",
      "d_nameLast2",
      "d_dateBirth2",
      "d_relationship2",
      "j_race2",
      "d_raceOther2",
      "date1",
      "date2",
      "f_sameAddress"
    ])
  },
  data() {
    return {
      menu: false,
      menu2: false,
      tab: 0
    };
  },
  watch: {
    date1() {
      this.d_dateBirth1 = this.$_formatDate(this.date1);
    },
    date2() {
      this.d_dateBirth2 = this.$_formatDate(this.date2);
    }
  },
  methods: {
    resetEntry() {
      this.$store.dispatch("householdsStore/resetEntry");
    },

    async openEntry() {
      this.resetEntry();
      /* Store authorized user in store */
      const counties = this.$store.getters["countiesStore/getCountyValueList"];
      if (counties.length === 0) {
        await this.$store.dispatch("countiesStore/valueList");
      }

      const data2 = {
        id: [2, 8, 12]
      };
      await this.$store.dispatch("valueListsStore/items", data2);

      const client = this.$store.getters["clientsStore/getClient"];

      await this.$store.dispatch("householdsStore/entry", client.fk_householdID);

      this.tab = 0;
      this.mode = 2;
      this.show = true;
    },

    cancelEntry() {
      this.tab = 0;
      this.show = false;
      this.resetEntry();
    },

    setMailingAddress(value) {
      if (value == 1) {
        this.fk_countyID_Mailing = this.fk_countyID_Residence;
        this.d_mailingStreet1 = this.d_residenceStreet1;
        this.d_mailingStreet2 = this.d_residenceStreet2;
        this.d_mailingCity = this.d_residenceCity;
        this.d_mailingState = this.d_residenceState;
        this.d_mailingPostalCode = this.d_residencePostalCode;
      } else {
        this.fk_countyID_Mailing = "";
        this.d_mailingStreet1 = "";
        this.d_mailingStreet2 = "";
        this.d_mailingCity = "";
        this.d_mailingState = "";
        this.d_mailingPostalCode = "";
      }
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        const entry = this.$store.getters["householdsStore/getEntry"];
        /* Create Record */
        if (this.mode === 1) {
          await this.$store.dispatch("householdsStore/create", entry);
        } else {
        /* Edit Record */
          await this.$store.dispatch("householdsStore/update", entry);
        }
        this.cancelEntry();
      }
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
