<template>
  <div>

    <!-- Title Bar For Section List-->
    <v-card flat>
      <v-toolbar dense flat color="secondary" class="mb-3" dark>
        <v-icon>fal fa-child</v-icon>
        <v-toolbar-title class="pl-3">Dependents</v-toolbar-title>
      </v-toolbar>
    </v-card>

    <!--List-->
    <list-basic
      :show="dependantsStore.dependants && dependantsStore.dependants.length > 0"
      :list-data="dependantsStore.dependants"
      :title="'Dependents'"
      :section="5.2"
      @open-detail="openEntry($event)"
    />

  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mixin } from '@/mixins/mixin.js';
import Pagination from '@/components/Pagination';
import ListBasic from '@/components/ListBasic';

export default {
  name: 'Dependants',
  components: {
    Pagination,
    ListBasic,
  },
  mixins: [mixin],
  computed: {
    ...mapState({
      dependantsStore: (state) => state.dependantsStore,
    }),
  },
  created() {
    this.initialize();
  },
  data() {
    return {
	    menu: false,
    };
  },
  methods: {
	  async initialize() {
      /* Clear previous list */
      this.$store.dispatch('dependantsStore/clearStore');
      /* Grab client detail */
      const client = this.$store.getters['clientsStore/getClient'];
      const data = {
        clientID: client.id,
        fk_householdID: client.fk_householdID,
      };
      /* Grab dependants list */
      await this.$store.dispatch('dependantsStore/fetch', data);
    },

    async loadValueLists() {
      const data2 = {
        id: [4, 7],
      };
      await this.$store.dispatch('valueListsStore/items', data2);
    },

    async openEntry(params) {
      /* If dependant is a client, go to client detail */
      if (params.status === 3) {
        this.$router.push({ name: 'client', params: { clientUUID: params.id } });
      }
      /* If not a client, open entry */
      else {
        await this.loadValueLists();
        await this.$store.dispatch('dependantsStore/entry', params.id);
      }
    },
  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
